import React from "react"
import { createGlobalStyle } from "styled-components"

// Import Utils
import themes from "~utils/themes"
import globalStyles from "~utils/globalStyles"

const GlobalStyle = createGlobalStyle(globalStyles)

export default () => {
  return <GlobalStyle theme={themes["light"]} />
}
