import "./src/assets/fonts/fonts.css"

export const shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation },
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  // current position will have a value if using history to return to a page
  if (currentPosition) {
    window.scrollTo(currentPosition)
  } else {
    // if no current position, check if moving to a new page
    if (location.pathname !== prevLocation.pathname) {
      // if on mobile and moving to a new page, reset to top of <Main />
      if (window.innerWidth < 800) {
        const yPosition = window.innerHeight * 0.8
        window.scrollTo({
          top: yPosition,
          left: 0,
          behavior: "smooth",
        })
        return false
        // if on desktop and moving to a new page, reset to top of page
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
    }
  }
  return false
}
