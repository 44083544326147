import React, { Fragment, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import { color, typography } from "styled-system"

import FilterHoveredContext from "~context/filterHovered"
import FilterContext from "~context/filter"

const Information = styled.div`
  ${typography}
  p {
    margin-bottom: 1rem;
  }
`

const Tags = styled.div`
  ${color}
  @media (max-width: 900px) {
    display: none;
  }
`

const Tag = styled(Link)`
  ${color}
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
`

export default () => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      sanityWebsite(id: { eq: "c69612d2-015f-5829-8e1a-e6dc9b7a3927" }) {
        id
        title
        _rawHomepageCopy
        tags {
          _id
          name
        }
      }
    }
  `)
  const {
    sanityWebsite: { _rawHomepageCopy, tags },
  } = data
  const [, setFilterHovered] = useContext(FilterHoveredContext)
  const [, setFilter] = useContext(FilterContext)

  const handleClick = ({ currentTarget }) => {
    const tag = currentTarget.innerText || currentTarget.textContent
    setFilter(tag.substring(0, tag.length - 1))
  }

  const handleOnMouseEnter = filter => {
    setFilterHovered(filter)
  }
  const handleOnMouseLeave = () => {
    setFilterHovered(null)
  }

  return (
    <Information fontFamily={"Optima"} fontWeight={"bold"}>
      <BlockContent blocks={_rawHomepageCopy} />
      <Tags>
        {tags.map(tag => {
          return (
            <Fragment key={tag._id}>
              <Tag
                onClick={handleClick}
                to="/"
                color={"yellow"}
                onMouseEnter={() => {
                  handleOnMouseEnter(tag.name)
                }}
                onMouseLeave={handleOnMouseLeave}
              >
                {tag.name}.
              </Tag>{" "}
            </Fragment>
          )
        })}
      </Tags>
    </Information>
  )
}
