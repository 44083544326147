import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { color } from "styled-system"

const Loader = styled.div`
  ${color}
  z-index: 1000;
  opacity: ${({ loaded }) => (loaded ? "0" : "1")};
  padding-top: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.5s;
`

export default () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  })
  return <Loader loaded={loaded} bg={"background"} />
}
