import React from "react"
import styled from "styled-components"
import { color } from "styled-system"
// import debounce from "lodash.debounce"

// Import Local Components
import Logos from "./components/logos"
import Navigation from "./components/navigation"
import Crawl from "./components/crawl"
import Information from "./components/information"
import FilterImagesFlash from "./components/filterImagesFlash"

const Header = styled.header`
  ${color}
  position: fixed;
  top: 0;
  bottom: 0;
  width: 33%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1.5rem;
  padding-left: 3rem;
  @media (max-width: 900px) {
    display: block;
    position: relative;
    width: 100%;
    padding: 0.75rem;
    min-height: 80vh;
    transition: flex-basis 0.3s, background-color 0.3s;
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 3rem);
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  @media (max-width: 900px) {
    min-height: calc(80vh - 3rem);
  }
`

export default () => {
  return (
    <Header color={"foreground"}>
      <Wrapper>
        <Logos />
        <Navigation />
        <Information />
      </Wrapper>
      <Crawl />
      <FilterImagesFlash />
    </Header>
  )
}
