import React, { useState } from "react"

const FilterHoveredContext = React.createContext()

const FilterHoveredContextProvider = ({ children }) => {
  const [filterHovered, setFilterHovered] = useState()
  return (
    <FilterHoveredContext.Provider value={[filterHovered, setFilterHovered]}>
      {children}
    </FilterHoveredContext.Provider>
  )
}

export default FilterHoveredContext
export { FilterHoveredContextProvider }
