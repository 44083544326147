import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { typography } from "styled-system"
import BlockContent from "@sanity/block-content-to-react"

const Crawl = styled.marquee`
  ${typography}
  opacity: 1;
  z-index: 10;
  color: ${({ theme }) => theme.colors.foreground};
  background: linear-gradient(
    to top,
    rgb(23, 23, 19) 40%,
    rgb(6, 6, 6) 71%,
    rgb(0, 0, 0) 70%,
    rgba(10, 10, 10, 0) 100%
  );
  position: fixed;
  left: 0;
  bottom: -1px;
  font-size: 1.2rem;
  > * {
    display: inline-block;
  }
  @media (min-width: 900px) {
    background: none;
    transform-origin: bottom left;
    transform: rotate(90deg);
    width: 110vh;
    letter-spacing: 1px;
    top: -5vh;
    left: 0;
    bottom: auto;
  }
  &:hover {
    opacity: 0.5;
  }
  transition: 0.3s opacity;
`

export default () => {
  const { sanityWebsite } = useStaticQuery(graphql`
    query MyQuery {
      sanityWebsite(id: { eq: "c69612d2-015f-5829-8e1a-e6dc9b7a3927" }) {
        id
        title
        _rawCrawlContent(resolveReferences: { maxDepth: 10 })
      }
    }
  `)
  return (
    <Link to="/newsletter">
      <Crawl
        behavior="scroll"
        direction="left"
        loop="infinite"
        fontFamily={"moonbase"}
      >
        <span>OPx Feed</span>
        <span role="img" aria-label="Fire">
          🔥
        </span>
        <span role="img" aria-label="Letter">
          📩
        </span>
        <span role="img" aria-label="Fire">
          🔥
        </span>
        <BlockContent blocks={sanityWebsite._rawCrawlContent} />
      </Crawl>
    </Link>
  )
}
