import React, { useState } from "react"

const FilterContext = React.createContext()

const FilterContextProvider = ({ children }) => {
  const [filter, setFilter] = useState("All")
  return (
    <FilterContext.Provider value={[filter, setFilter]}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
export { FilterContextProvider }
