// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-index-js": () => import("./../src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-components-contact-index-js": () => import("./../src/pages/contact/components/contact/index.js" /* webpackChunkName: "component---src-pages-contact-components-contact-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */)
}

