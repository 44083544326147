const sharedStyles = {
  breakpoints: ["900px"],
  fonts: {
    moonbase: ["Moonbase Alpha"],
    archivo: ["Archivo, Helvetica, sans-serif"],
    optima: ["Optima, Helvetica, san-serif"],
  },
  fontSizes: {
    p: ["10px"],
    h1: ["18px"],
  },
  lineHeight: [1],
  letterSpacing: [0],
  system: {
    speed: {
      slow: "0.5s",
      fast: "0.3s",
    },
  },
}
export default {
  light: {
    ...sharedStyles,
    colors: {
      foreground: "#d6d6d6",
      background: "#100f14",
      yellow: "#f1c703",
    },
  },
  dark: {
    ...sharedStyles,
    colors: {
      foreground: "#ffffff",
      background: "#000000",
    },
  },
  system: {
    speed: {
      fast: "0.3s",
    },
  },
}
