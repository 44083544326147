export default {
  div: {
    "box-sizing": "border-box",
  },
  "html, body": {
    "overscroll-behavior": "none",
    "font-size": "14px",
  },
  html: {
    "text-rendering": "optimizeLegibility",
  },
  body: {
    margin: 0,
    padding: 0,
    width: "100vw",
    color: props => props.theme.colors.background,
    "background-color": props => props.theme.colors.foreground,
    "font-family": props => props.theme.fonts.optima,
    transition: ({ theme: { system } }) =>
      `color ${system.speed.fast}, background-color ${system.speed.fast}`,
  },
  a: {
    color: props => props.theme.colors.background,
  },
  ul: {
    "list-style": "none",
    padding: 0,
    margin: 0,
  },
  ol: {
    margin: 0,
  },
  h1: { margin: 0 },
  h2: { margin: 0 },
  h3: { margin: 0 },
  h4: { margin: 0 },
  p: { margin: 0 },
  "ul[class]": { margin: 0 },
  "ol[class]": { margin: 0 },
  li: { margin: 0, "list-style": "none" },
  figure: { margin: 0 },
  figcaption: { margin: 0 },
  blockquote: { margin: 0 },
  dl: { margin: 0 },
  dd: {
    margin: 0,
  },
  img: {
    "max-width": "100%",
  },
}
