import React from "react"

// Import Global Components
import SEO from "~components/seo"
import { FilterContextProvider } from "~context/filter"
import { FilterHoveredContextProvider } from "~context/filterHovered"

// Import Local Components
import ThemeProvider from "./components/themeProvider"
import GlobalStyle from "./components/globalStyle"
import Loader from "./components/loader"
import Layout from "./components/layout"

export default ({ children }) => (
  <FilterContextProvider>
    <FilterHoveredContextProvider>
      <ThemeProvider>
        <SEO />
        <GlobalStyle />
        <Loader />
        <Layout>{children}</Layout>
      </ThemeProvider>
    </FilterHoveredContextProvider>
  </FilterContextProvider>
)
