import React, { useRef, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { color } from "styled-system"

import FilterHoveredContext from "~context/filterHovered"

const Container = styled.div`
  ${color}
  position: fixed;
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 33%;
  ${"" /* padding: 0.75rem; */}
  bottom: 0;
  @media (max-width: 900px) {
    position: absolute;
    padding: 0;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const VideoWrapper = styled.div`
  opacity: ${({ filterHovered, filter }) => (filterHovered === filter ? 1 : 0)};
  display: ${({ filterHovered, filter }) =>
    filterHovered === filter ? "block" : "none"};
  position: absolute;
  min-height: 100vh;
  max-width: none;
  object-fit: cover;
  @media (max-width: 900px) {
    display: none;
  }
`
export default () => {
  const [filterHovered] = useContext(FilterHoveredContext)

  // get tag animations
  const {
    allSanityTag: { edges },
  } = useStaticQuery(graphql`
    query TagImagesQuery {
      allSanityTag {
        edges {
          node {
            name
            animation {
              asset {
                _id
                url
              }
            }
            animationMp4 {
              asset {
                _id
                url
              }
            }
          }
        }
      }
    }
  `)

  // const tags = edges.map(edge => edge.node)
  const tags = edges.reduce((accumulator, currentValue) => {
    accumulator[currentValue.node.name] = currentValue.node
    return accumulator
  }, {})

  // manually play all refs
  const creativeDirectionRef = useRef()
  const brandingRef = useRef()
  const experientialRef = useRef()
  const liveRef = useRef()
  const filmRef = useRef()
  const digitalRef = useRef()

  return (
    <Container bg={"background"}>
      <VideoWrapper filter={"Creative Direction"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={creativeDirectionRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Creative Direction"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Creative Direction"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
      <VideoWrapper filter={"Branding"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={brandingRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Branding"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Branding"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
      <VideoWrapper filter={"Experiential"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={experientialRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Experiential"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Experiential"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
      <VideoWrapper filter={"Live"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={liveRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Live"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Live"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
      <VideoWrapper filter={"Film"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={filmRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Film"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Film"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
      <VideoWrapper filter={"Digital"} filterHovered={filterHovered}>
        <ReactPlayer
          ref={digitalRef}
          playing
          loop
          muted
          playsinline
          url={[
            {
              src: tags["Digital"].animation.asset.url,
              type: "video/webm",
            },
            {
              src: tags["Digital"].animationMp4.asset.url,
              type: "video/mp4",
            },
          ]}
          style={{ position: "absolute", top: 0, left: 0 }}
          width="auto"
          height="100vh"
        />
      </VideoWrapper>
    </Container>
  )
}
