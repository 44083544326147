import React, { useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { color } from "styled-system"
import debounce from "lodash.debounce"

import FilterContext from "~context/filter"

import Tags from "./components/tags"

const Main = styled.main`
  ${color}
  box-sizing: border-box;
  position: absolute;
  min-height: 100vh;
  width: 76vw;
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  flex-basis: 66%;
  flex-wrap: wrap;
  top: 0;
  left: ${({ isHovered }) => (isHovered ? "23%" : "33%")};
  padding: 1.5rem;
  padding-top: 3rem;
  transition: left 0.3s;
  @media (max-width: 900px) {
    position: relative;
    display: block;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    left: auto;
    right: auto;
  }
  @media (min-width: 900px) {
    box-shadow: -15px -10px 8px -8px rgba(16, 15, 20, 0.5);
  }
`

export default ({ children }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [filter, setFilter] = useContext(FilterContext)

  const debouncedSetIsHovered = debounce(setIsHovered, 300, { leading: true })

  const {
    sanityWebsite: { tags },
  } = useStaticQuery(graphql`
    query TagsQuery {
      sanityWebsite(id: { eq: "c69612d2-015f-5829-8e1a-e6dc9b7a3927" }) {
        id
        title
        _rawHomepageCopy
        tags {
          _id
          name
        }
      }
    }
  `)

  const handleClick = ({ currentTarget }) => {
    const tag = currentTarget.innerText || currentTarget.textContent
    setFilter(tag)
  }

  return (
    <Main
      bg={"foreground"}
      id="Main"
      isHovered={isHovered}
      onMouseEnter={() => debouncedSetIsHovered(true)}
      onMouseLeave={() => debouncedSetIsHovered(false)}
    >
      <Tags
        tags={tags}
        handleClick={handleClick}
        active={filter}
        isHovered={isHovered}
      />
      {children}
    </Main>
  )
}
