import React from "react"
import styled from "styled-components"

// Import Global Components
import Header from "~components/header"
import Main from "~components/main"

const Layout = styled.div`
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    display: block;
  }
`

export default ({ children }) => (
  <Layout>
    <Header />
    <Main>{children}</Main>
  </Layout>
)
