import React from "react"
import styled from "styled-components"
import { color, typography } from "styled-system"

// import global components
import { Link as GatsbyLink } from "gatsby"

const Navigation = styled.nav`
  ${typography}
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 900px) {
    &:after {
      content: "";
      position: absolute;
      width: 400px;
      height: 400px;
      left: -100px;
      border-radius: 200px;
      border: 1px solid ${({ theme }) => theme.colors.foreground};
      pointer-events: none;
    }
  }
`

const Link = styled(GatsbyLink)`
  ${color}
  display: block;
  font-size: 2.5rem;
  text-decoration: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.foreground};
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.3s;
`
const Spacer = styled.div`
  ${color}
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
`

export default () => (
  <Navigation fontFamily={"moonbase"}>
    <Link to="/" color={"foreground"}>
      Work
    </Link>
    <Link to="/contact" color={"foreground"}>
      Contact
    </Link>
    <Spacer color={"yellow"}>+++</Spacer>
    <Link to="/newsletter" color={"foreground"}>
      OPx
    </Link>
    <p style={{ marginTop: "0.5rem" }}>^ very cool newsletter</p>
  </Navigation>
)
