import React from "react"
import styled from "styled-components"
import { color } from "styled-system"
import { Link } from "gatsby"
import LogoSrc from "./assets/offline-projects-white.png"

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TextMark = styled(Link)`
  ${color}
  height: fit-content;
  border-bottom: 2px solid ${({ theme }) => theme.colors.foreground};
  text-transform: uppercase;
  font-weight: normal;
  white-space: nowrap;
  text-decoration: none;
  font-size: 2rem;
  align-self: center;
  margin-bottom: 1rem;
`

const Logo = styled.img`
  height: 3.5rem;
`

export default () => (
  <Logos>
    <TextMark to="/" color={"foreground"}>
      Offline Projects
    </TextMark>
    <Logo src={LogoSrc} />
  </Logos>
)
