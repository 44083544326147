import React, { useContext } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import styled from "styled-components"
import { color, typography } from "styled-system"

// Import Global Context
import FilterHoveredContext from "~context/filterHovered"

const Tags = styled.div`
  ${color}
  position: fixed;
  top: 0;
  left: ${({ isHovered }) => (isHovered ? "23%" : "33%")};
  width: 77vw;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;

  z-index: 2;
  transition: left 0.3s;
  @media (max-width: 900px) {
    position: relative;
    width: 100%;
    padding: 0.75rem;
    left: auto;
    flex-wrap: wrap;
  }
  & > * {
    opacity: ${({ visible }) => (visible ? "1" : 0)};
    pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
    transition: opacity 0.3s;
  }
`

const Back = styled(Link)`
  ${color}
  ${typography}
  width: ${({ visible }) => (visible ? "10ch;" : "0ch")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};;
  display: inline-block;
  font-size: 1.3rem;
  text-decoration: none;
  :after {
    display: inline-block;
    content: "-";
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  white-space: nowrap;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
  @media (max-width: 900px) {
    display: none;
  }
`

const Title = styled.p`
  ${color}
  ${typography}
  display: inline-block;
  font-size: 1.3rem;
  :after {
    display: inline-block;
    content: "-";
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  white-space: nowrap;
`

const Tag = styled(Link)`
  ${typography}
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  text-decoration: ${({ active, name }) =>
    active === name ? "underline" : "none"};
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  &:after {
    display: inline-block;
    content: "/";
    text-decoration: none !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  &:last-of-type {
    :after {
      display: none;
    }
  }
`

const PlusIcon = styled.div`
  font-family: "Moonbase Alpha";
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 1rem;
  @media (max-width: 900px) {
    display: none;
  }
`

export default ({ active, tags, handleClick, isHovered }) => {
  const [, setFilterHovered] = useContext(FilterHoveredContext)

  if (tags[0].name !== "All") {
    tags.unshift({ _id: "All", name: "All" })
  }
  const handleOnMouseEnter = filter => {
    setFilterHovered(filter)
  }
  const handleOnMouseLeave = () => {
    setFilterHovered(null)
  }

  return (
    <Location>
      {({ location }) => {
        return (
          <Tags
            bg={"yellow"}
            color={"background"}
            isHovered={isHovered}
            visible={
              !!(
                location.pathname === "/" ||
                location.pathname.includes("project")
              )
            }
          >
            <Back
              fontFamily={"moonbase"}
              to="/"
              visible={location.pathname.includes("project")}
            >
              &lt;&lt;&lt; Back
            </Back>
            <Title fontFamily={"moonbase"}>Filters</Title>
            {tags.map(tag => {
              return (
                <Tag
                  to="/"
                  key={tag._id}
                  onClick={handleClick}
                  active={active}
                  name={tag.name}
                  fontFamily={"optima"}
                  fontWeight={"bold"}
                  onMouseEnter={() => {
                    handleOnMouseEnter(tag.name)
                  }}
                  onMouseLeave={handleOnMouseLeave}
                >
                  {tag.name}
                </Tag>
              )
            })}
            <PlusIcon>+</PlusIcon>
          </Tags>
        )
      }}
    </Location>
  )
}
